import React, { useEffect, useReducer, useRef } from "react";
import {v4 as uuidv4} from 'uuid';

import GlobalContext from "./GlobalContext";
import plaintextProcess from "../helpers/plaintextProcess";
import setCursorToEnd from "../helpers/setCursorToEnd";
import stripMessage from "../helpers/stripMessage";

//const apiUrl = 'http://localhost:9000';
const apiUrl = 'https://compliance.demo.reachality.ai/api';
//const apiUrl = '/api';
const agentSlug = window.location.pathname.substring(1);
const token = '49e2f7e3-6f0c-4ef0-9b6d-9071d8e11d3a';

let config = {
  headers: { 
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  }
};

const reducer = (state, action) => {
  switch(action.type)
  {
    case 'change message':
      return {
        ...state,
        letterCount: action.value.length,
        message: action.value,
        processPaste: false
      }
    case 'chat response':
      return {
        ...state,
        loading: false,
        messageHistory: [ ...state.messageHistory, action.message ]
      }
    case 'clear message history':
      return {
        ...state,
        messageHistory: []
      }
    case 'set conversation':
      return {
        ...state,
        conversation: action.conversation,
        initialized: true
      }
    case 'set agent':
      return {
        ...state,
        agentId: action.agentId
      }
    case 'set agent error':
      return {
        ...state,
        agentError: true
      }
    case 'stream chat finish':
      return {
        ...state,
        responding: false
      }
    case 'stream chat response':
      let newHistory = [ ...state.messageHistory ];
      newHistory[ newHistory.length-1 ].content += action.chunk;
      return {
        ...state,
        loading: false,
        messageHistory: newHistory
      }
    case 'stream chat response init':
      return {
        ...state,
        loading: true,
        messageHistory: [ ...state.messageHistory, action.message ],
        responding: true
      }
    case 'submit message':
      const stripped = state.message.replace(/(^[\r\n\s]+)|([\r\n\s]+$)/, '');
      return {
        ...state,
        letterCount: null,
        loading: true,
        message: null,
        messageHistory: [ ...state.messageHistory, {
          from: 'user',
          datetime: new Date(),
          content: stripped
        } ]
      }
    case 'trigger paste':
      return {
        ...state,
        processPaste: true
      }
    default:
  }
}

export default function GlobalState(props) {

  const [ state, dispatch ] = useReducer(reducer, {
    agentError: false,
    authorize: false,
    conversation: null,
    initialized: false,
    letterCount: null,
    loading: false,
    message: null,
    messageHistory: [],
    processPaste: false,
    agentId: null,
    responding: false
  });

  useEffect(() => { 
    getAgentSettings();
  }, []);

  useEffect(() => { 
    if(state.agentId !== null){
      getConversation();
    }
  }, [state.agentId]);

  const textareaRef = useRef();

  const changeMessage = e => {
    const processedMessage = plaintextProcess(e.target.childNodes);
    const strippedMessage = stripMessage(processedMessage);

    if(state.processPaste){
      textareaRef.current.textContent = strippedMessage;
      setCursorToEnd(textareaRef.current)
    }
    dispatch({
      type: 'change message',
      value: strippedMessage
    });
  }

  const clearMessageHistory = () => {
    dispatch({
      type: 'clear message history'
    });
  }

  const detectReturn = e => {
    if(e.keyCode === 13 && !e.shiftKey){
      e.preventDefault();
      submitMessage();
    }
  }

  async function getAgentSettings(){
    const url = `${apiUrl}/agent-settings/${agentSlug}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: config.headers
    });
    const data = await response.json();
    if(!data.error && data.agentId){
      dispatch({
        type: 'set agent',
        agentId: data.agentId
      });
    } else {
      dispatch({
        type: 'set agent error'
      });
    }
  }

  async function getConversation() {
    let conversation = localStorage.getItem('conversation-'+state.agentId);
    
    if(conversation === null){
      const url = `${apiUrl}/conversation/new`;

      const response = await fetch(url, {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify({
          name: 'Lumina ' + uuidv4(),
          agentId: state.agentId
        })
      });
      const data = await response.json();
      if(data.session_id){
        conversation = data.session_id;
        localStorage.setItem('conversation-'+state.agentId, conversation)
      }
      dispatch({
        type: 'set conversation',
        conversation: conversation
      });
    } else {
      dispatch({
        type: 'set conversation',
        conversation: conversation
      });
    }
  }

  async function luminaAPI(prompt) {
    const url = `${apiUrl}/message`;
    
    dispatch({
      type: 'stream chat response init',
      message: {
        from: 'bot',
        datetime: new Date(),
        content: ''
      }
    });

    const response = await fetch(url, {
      method: 'POST',
      headers: config.headers,
      body: JSON.stringify({
        prompt: prompt,
        agentId: state.agentId,
        conversation: state.conversation
      })
    });
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let result = await reader.read();

    while (!result.done) {
      const chunk = decoder.decode(result.value);
      const splitNl = chunk.split("\n");
      const events = splitNl.filter(item =>{ return item !== '' && item.substring(0, 6) !== 'event:' });
      events.forEach(event=>{
        const json = event.substring(6);
        let data
        try {
          data = JSON.parse(json);
        } catch (e) {
          console.log(json);
          return console.error(e);
        }

        if('message' in data){
          dispatch({
            type: 'stream chat response',
            chunk: data.message
          });
        } else if(data.status === 'finish') {
          dispatch({
            type: 'stream chat finish',
            chunk: data.message
          });

          const selection = window.getSelection();
          const range = document.createRange();
          selection.removeAllRanges();
          range.selectNodeContents(textareaRef.current);
          range.collapse(false);
          selection.addRange(range);
          textareaRef.current.focus();
        }
      });

      result = await reader.read();
    }
  }

  const processPaste = e => {
    dispatch({
      type: 'trigger paste'
    });
  }

  const submitMessage = () => {
    dispatch({
      type: 'submit message'
    });
    let trimmedMessage = state.message?.trim();
    trimmedMessage = trimmedMessage.replace(/\?*$/,'');
    luminaAPI(trimmedMessage);
  }

  const actions = {
    changeMessage: changeMessage,
    clearMessageHistory: clearMessageHistory,
    detectReturn: detectReturn,
    processPaste: processPaste,
    submitMessage: submitMessage
  };

  const globalState = {
    state: state,
    actions: actions,
    refs: {
      textareaRef: textareaRef
    }
  }

  return (
    <GlobalContext.Provider value={ globalState }>
      {props.children}
    </GlobalContext.Provider>
  );
}