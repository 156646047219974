import React from "react";
import MessageTime from "../MessageTime/MessageTime";

import './MessageText.scss';

function MessageText(props){

  return (
    <div className='message'>
      <div className='content'>{props.content}</div>
      <MessageTime datetime={props.datetime} />
    </div>
  );
}

export default MessageText;